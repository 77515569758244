<template>
  <div class="login">
    <!-- 轮播 -->
    <div class="carousel" @click="skip('/')">
      <div class="carousel-main">
        <img class="logo" src="@/assets/images/logo-white.png" />
        <el-carousel indicator-position="outside" arrow="never" trigger="click">
          <el-carousel-item v-for="item in carouselList" :key="item">
            <p>{{ item }}</p>
          </el-carousel-item>
        </el-carousel>
        <img class="earth" src="https://wjj-core.oss-cn-beijing.aliyuncs.com/earth.png" />
      </div>
    </div>
    <!-- 表单 -->
    <div class="from">
      <!-- 切换登录注册 -->
      <div class="from-type">
        <span>{{ loginType === 3 ? "没有账户?" : "已经注册过?" }}</span>
        <el-button round @click="changeLoginType">{{
          loginType === 3 ? "注册" : "登录"
        }}</el-button>
      </div>
      <!-- 中间 -->
      <div class="card-box">
        <div class="title-box">
          <div class="title1">
            <span></span>
            <div>Welcome</div>
            <span></span>
          </div>
        </div>
        <div v-if="loginType === 1" class="inputs">
          <div class="title"><span>*</span>电子邮箱</div>
          <div class="input-box">
            <input
              type="text"
              v-model="signName"
              placeholder="请输入邮箱账号"
              maxlength="1000"
            />
            <img
              class="left"
              src="@/assets/images/home/account.png"
              alt="图片"
            />
          </div>
        </div>
        <div v-if="loginType === 2" class="inputs">
          <div class="input-box lock">
            <input
              :type="password1"
              v-model="signPassword"
              maxlength="1000"
              placeholder="请输入密码"
            />
            <img class="left" src="@/assets/images/home/lock.png" alt="图片" />
            <img
              class="right"
              @click="changeEye(1)"
              :src="imgurl1"
              alt="图片"
            />
          </div>
          <div class="input-box lock">
            <input
              :type="password2"
              v-model="confirmPassword"
              maxlength="1000"
              placeholder="请再次输入密码"
            />
            <img class="left" src="@/assets/images/home/lock.png" alt="图片" />
            <img
              class="right"
              :src="imgurl2"
              @click="changeEye(2)"
              alt="图片"
            />
          </div>
        </div>
        <div v-if="loginType === 3" class="inputs">
          <div class="input-box">
            <input
              type="text"
              v-model="logonName"
              placeholder="请输入邮箱账号"
              maxlength="1000"
            />
            <img
              class="left"
              src="@/assets/images/home/account.png"
              alt="图片"
            />
          </div>
          <div class="input-box lock">
            <input
              :type="password3"
              placeholder="请输入密码"
              v-model="logonPassword"
              maxlength="1000"
            />
            <img class="left" src="@/assets/images/home/lock.png" alt="图片" />
            <img
              class="right"
              :src="imgurl3"
              @click="changeEye(3)"
              alt="图片"
            />
          </div>
          <div class="input-box code">
            <input
              placeholder="请输入验证码"
              v-model="captchaValue"
              maxlength="4"
            />
            <img
              class="left"
              src="@/assets/images/home/shield.png"
              alt="图片"
            />
            <img
              class="security-code"
              @click="getCaptcha"
              :src="captchaImg"
              alt="图片"
            />
          </div>
        </div>
        <el-button @click="submit">{{
          loginType === 3 ? "登录" : "注册"
        }}</el-button>
        <div
          v-if="loginType === 3"
          class="forget-password"
          @click="forgetPassword"
        >
          忘记密码？
        </div>
        <div class="term">
          <img
            v-show="loginType !== 3"
            :src="isAgree ? agreeImg : disAgreeImg"
            alt="图片"
            @click="isAgree = !isAgree"
          />
          <div v-show="loginType !== 3">
            注册即代表您已阅读并同意<span>【隐私政策】</span>和<span
              >【服务协议】</span
            >
          </div>
        </div>
      </div>
      <div class="copyright" @click="jumpOut">
        Copyright ©2016-2023 南京百数来信息科技有限公司 苏ICP备17022236号-3
      </div>
    </div>
  </div>
</template>
<script>
import { getCaptcha, setLogin, setSignUp } from "@/api/user";
import { setStorage } from "@/utils/auth";
export default {
  data() {
    return {
      carouselList: ["自助市场调查", "流量变现", "速度快、质量高、价格低"],
      loginType: 1,
      password1: "password",
      imgurl1: require("@/assets/images/home/eye-close.png"),
      password2: "password",
      imgurl2: require("@/assets/images/home/eye-close.png"),
      password3: "password",
      imgurl3: require("@/assets/images/home/eye-close.png"),
      agreeImg: require("@/assets/images/home/agree.png"),
      disAgreeImg: require("@/assets/images/home/disagree.png"),
      isAgree: false,
      captchaImg: null, //图形验证码
      captchaId: null, //图形验证码id
      logonName: "", //登录账号
      logonPassword: "", //登录密码
      captchaValue: "", //登录时验证码内容
      signName: "", //注册账号
      signPassword: "", //登录密码
      confirmPassword: "", //确认密码
    };
  },
  mounted() {
    // 判断是否为登录
    if (this.$route.query.loginType) {
      this.loginType = parseInt(this.$route.query.loginType);
    }
    this.getCaptcha();
  },
  methods: {
    // 跳转
    skip(url) {
      this.$router.push({
        path: url,
      });
    },
    //切换登录注册
    changeLoginType() {
      if (this.loginType === 3) {
        this.loginType = 1;
      } else {
        this.loginType = 3;
      }
    },
    //点击眼睛
    changeEye(type) {
      console.log("type", type);
      switch (type) {
        case 1:
          if (this.password1 === "password") {
            this.password1 = "text";
            this.imgurl1 = require("@/assets/images/home/eye-open.png");
          } else {
            this.password1 = "password";
            this.imgurl1 = require("@/assets/images/home/eye-close.png");
          }
          break;
        case 2:
          if (this.password2 === "password") {
            this.password2 = "text";
            this.imgurl2 = require("@/assets/images/home/eye-open.png");
          } else {
            this.password2 = "password";
            this.imgurl2 = require("@/assets/images/home/eye-close.png");
          }
          break;
        case 3:
          if (this.password3 === "password") {
            this.password3 = "text";
            this.imgurl3 = require("@/assets/images/home/eye-open.png");
          } else {
            this.password3 = "password";
            this.imgurl3 = require("@/assets/images/home/eye-close.png");
          }
          break;
      }
    },
    //获取图形验证码
    getCaptcha() {
      getCaptcha().then((res) => {
        this.captchaImg = "data:image/png;base64," + res.data.base64;
        this.captchaId = res.data.id;
      });
    },
    //用户登录
    setLogin() {
      const params = {
        name: this.logonName,
        password: this.logonPassword,
        captchaId: this.captchaId,
        captcha: this.captchaValue,
      };
      setLogin(params).then((res) => {
        if (res.status === 200) {
          setStorage("token", res.data.token);
          this.$store.commit("user/setToken", res.data.token);
          this.$router.push({
            path: "/homePage/index",
          });
          this.$message({
            showClose: true,
            message: "登录成功",
            type: "success",
          });
        } else {
          this.getCaptcha();
        }
      });
    },
    //用户注册
    setSignUp() {
      const params = {
        name: this.signName,
        password: this.signPassword,
        confirmPassword: this.confirmPassword,
      };
      setSignUp(params).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: "注册成功,请登录",
            type: "success",
          });
          this.loginType = 3;
        } else {
          this.loginType = 1;
        }
      });
    },
    submit() {
      switch (this.loginType) {
        case 1:
          if (this.isAgree) {
            this.loginType = 2;
          } else {
            this.$message({
              showClose: true,
              message: "请先同意隐私政策和用户协议",
              type: "warning",
            });
          }
          break;
        case 2:
          if (this.isAgree) {
            this.setSignUp();
          } else {
            this.$message({
              showClose: true,
              message: "请先同意隐私政策和用户协议",
              type: "warning",
            });
          }
          break;
        case 3:
          this.setLogin();
          break;
      }
    },
    //跳出
    jumpOut() {
      window.open("https://beian.miit.gov.cn");
    },
    //忘记密码
    forgetPassword() {
      this.$router.push("/forgetPassword");
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #f5f6fc;
  cursor: pointer;
  .carousel {
    width: 400px;
    min-height: 100vh;
    .carousel-main {
      background: linear-gradient(151deg, #4578ff 0%, #425ccf 100%);
      height: 100%;
      .logo {
        width: 90px;
        height: 40px;
        margin-top: 28px;
        margin-left: 35px;
      }
      p {
        font-size: 19px;
        color: #fff;
        text-align: center;
        margin-top: 110px;
      }
      .earth {
        width: 360px;
        height: 360px;
        margin: 50px 20px;
      }
    }
  }
  .from {
    position: relative;
    width: calc(100% - 400px);
    min-height: 700px;
    .from-type {
      width: 100%;
      margin-top: 44px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        font-size: 14px;
        color: #232323;
      }
      .el-button {
        padding: 0;
        width: 75px;
        height: 30px;
        line-height: 30px;
        color: #232323;
        cursor: pointer;
        margin-right: 50px;
        margin-left: 10px;
      }
      .el-button:hover {
        color: #409eff;
      }
    }
    .copyright {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      font-size: 12px;
      color: #232323;
      word-spacing: 7px;
    }
  }
}
.card-box {
  position: absolute;
  top: 210px;
  left: 50%;
  transform: translateX(-50%);
  width: 420px;
  background: #ffffff;
  border-radius: 4px;
  .title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    .title1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        width: 60px;
        height: 1px;
        background-color: #4578ff;
      }
      div {
        font-size: 30px;
        color: #4578ff;
        margin: 0 21px;
      }
    }
    .title2 {
      margin-top: 20px;
    }
  }
  .el-button {
    padding: 0;
    height: 50px;
    width: 350px;
    background-color: #4578ff;
    color: #fff;
    margin-left: 35px;
    font-size: 13px;
    margin-top: 20px;
  }
  .forget-password {
    float: right;
    width: 100px;
    text-align: right;
    margin-top: 15px;
    margin-right: 35px;
    font-size: 14px;
    color: #4578ff;
  }
  .term {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 35px;
    margin-top: 20px;
    margin-bottom: 50px;
    color: #666666;
    img {
      width: 12px;
      height: 11px;
      cursor: pointer;
      margin-right: 5px;
    }
    span {
      color: #4578ff;
    }
  }
}
.inputs {
  padding: 0 35px;
  .title {
    font-size: 12px;
    color: #06052b;
    margin-top: 30px;
    span {
      color: #e52323;
    }
  }
  .input-box {
    position: relative;
    .left {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 15px;
      top: 29px;
    }
    .right {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 15px;
      top: 29px;
      cursor: pointer;
    }
  }
  .lock {
    input {
      width: calc(100% - 106px);
      padding-left: 52px;
      padding-right: 52px;
    }
  }
  .code {
    input {
      width: 118px;
    }
    .security-code {
      position: absolute;
      top: 20px;
      right: 0;
      width: 131px;
      height: 41.6px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
<style scoped>
.carousel /deep/ .el-carousel__container {
  height: 150px;
  min-height: 10px;
}
.carousel /deep/ .el-carousel__indicators {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  display: none;
}
.carousel /deep/ .el-carousel__indicator {
  margin-right: 20px;
}
.carousel /deep/ .el-carousel__button {
  width: 24px;
  height: 8px;
  border-radius: 4px;
  background-color: #fff;
}

input {
  width: calc(100% - 54px);
  height: 40px;
  border: 1px solid #cfd0d3;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 4px;
  padding-left: 52px;
  color: #232323;
}

input:focus {
  border-color: #4578ff;
}
</style>